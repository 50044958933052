import React from 'react'
import { Card, Paragraph, Heading, CallToAction } from '@scentregroup/ui'
import { useAnalytics } from '@scentregroup/shared/analytics'
import { Link } from '@scentregroup/shared/link'
import { Link as HubLink } from '@hub/link'
import analyticsUtils from '../../helpers/analytics-utils'
import { createProductClickEvent } from '../../helpers/analytics-utils/productClick'
import { CFEntryImageSet } from '@scentregroup/shared/types/contentful'
import {
  CloudinaryImage,
  IMAGE_SIZES_HALF_HALF,
} from '../../hub-components/cloudinary-image'
import style from './index.module.scss'

interface UtilityBoxItem {
  url: string
  title: string
}

interface ComparisonItemProps {
  productName: string
  dotPoints: string[]
  callToAction: { url: string; category: string }
  utilityBox?: { heading: string; items: UtilityBoxItem[] }
  heroImageSet: CFEntryImageSet
}

const ComparisonItem: React.FunctionComponent<
  React.PropsWithChildren<ComparisonItemProps>
> = ({
  productName,
  dotPoints,
  utilityBox,
  callToAction,
  heroImageSet,
}): JSX.Element => {
  const analytics = useAnalytics()
  const linkOnClick = (item: UtilityBoxItem): void => {
    analytics.trackEvent({
      type: 'legacy',
      data: {
        event: analyticsUtils.events.USER_CLICK,
        eventCategory: productName,
        eventLabel: item.title,
        eventAction: analyticsUtils.categories.COMPARISON_MODULE_CTA,
      },
    })
  }

  return (
    <div className={style.comparisonItem}>
      <Card
        heading={productName}
        className={style.card}
        headingProps={{ type: 'medium' }}
      >
        {heroImageSet?.master?.url && (
          <CloudinaryImage
            sx={{ margin: 'spacing-lg' }}
            imageSetOrImage={{
              url: heroImageSet.master.url,
              type: 'WIDE',
              alt: heroImageSet.description,
            }}
            resizeMode="pad"
            ratio="4/3"
            sizes={IMAGE_SIZES_HALF_HALF}
          />
        )}
        <div className={style.childrenWrapper}>
          <div className={style.dotPoints}>
            {dotPoints.map(point => (
              <Paragraph key={point}>{point}</Paragraph>
            ))}
          </div>

          <CallToAction
            rel="noopener"
            target="_self"
            element={Link}
            to={callToAction.url}
            className={style.callToAction}
            onClick={
              productName
                ? () =>
                    createProductClickEvent(
                      productName,
                      analyticsUtils.categories.COMPARISON_MODULE_CTA,
                      callToAction.category
                    )
                : undefined
            }
          >
            {callToAction.category}
          </CallToAction>
          {utilityBox && utilityBox.items.length > 0 && (
            <div className={style.utilityBox}>
              <Heading type="small">{utilityBox.heading}</Heading>
              {utilityBox.items.map(item => (
                <HubLink
                  rel="noopener"
                  target="_self"
                  href={item.url}
                  key={item.title}
                  onClick={() => linkOnClick(item)}
                >
                  <Paragraph className={style.utilityLinkText}>
                    {item.title}
                  </Paragraph>
                </HubLink>
              ))}
            </div>
          )}
        </div>
      </Card>
    </div>
  )
}

export default ComparisonItem
