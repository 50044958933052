import React from 'react'
import { CallToAction, Container, Paragraph } from '@scentregroup/ui'
import { Link } from '@scentregroup/shared/link'
import { promotionType } from '../../types'
import analyticsUtils from '../../helpers/analytics-utils'
import FlipFlop from '../flip-flop'
import Article from '../article'
import styles from './index.module.scss'

const { GIFTCARD_CLICK, CORP_MODULE_CTA } = analyticsUtils.categories

const Promotion = ({
  image,
  title,
  heading,
  shortDescription,
  callToActionLabel,
  callToActionAddress,
  eventCategory,
  eventAction,
  analyticsMetadata,
}) => {
  // N.B. use of a separator to put data for two buttons in one field is a tactical
  // fix to get W+ promotion out ASAP. Should be eventually removed, or replaced with
  // a durable content model solution.
  const SEPARATOR = ';;'
  const [primaryCtaLabel, secondaryCtaLabel] =
    callToActionLabel.split(SEPARATOR)
  const [primaryCtaAddress, secondaryCtaAddress] =
    callToActionAddress.split(SEPARATOR)
  const hasSecondary = Boolean(secondaryCtaLabel && secondaryCtaAddress)
  return (
    <Container centred type="large">
      <FlipFlop
        position="right"
        size="full"
        imageUrl={image.url}
        lazyLoad={false}
        analyticsMetadata={analyticsMetadata}
        heading={title}
      >
        <Article superheading={heading} heading={title}>
          <Paragraph>{shortDescription}</Paragraph>
          <Container type="full" className={styles.ctaContainer}>
            <CallToAction
              rel="noopener"
              target="_self"
              element={Link}
              to={primaryCtaAddress}
              eventCategory={eventCategory}
              eventAction={eventAction}
              eventLabel={primaryCtaLabel}
              data-test-id="promotion-link"
              fullWidth={hasSecondary}
            >
              {primaryCtaLabel}
            </CallToAction>
            {hasSecondary && (
              <CallToAction
                rel="noopener"
                target="_self"
                inferior
                fullWidth
                element={Link}
                to={secondaryCtaAddress}
                eventCategory={eventCategory}
                eventAction={eventAction}
                eventLabel={secondaryCtaLabel}
                data-test-id="promotion-link"
              >
                {secondaryCtaLabel}
              </CallToAction>
            )}
          </Container>
        </Article>
      </FlipFlop>
    </Container>
  )
}

Promotion.propTypes = promotionType.isRequired

Promotion.defaultProps = {
  eventCategory: GIFTCARD_CLICK,
  eventAction: CORP_MODULE_CTA,
}

export default Promotion
